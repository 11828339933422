<template>
  <main>
    <div class="form">
      <span class="big">Смена пароля</span>
      <input
        v-if="!isResettingPassword"
        type="password"
        placeholder="Старый пароль"
        autocomplete="current-password"
        v-model="currentPassword"
      />
      <input type="password" placeholder="Новый пароль" autocomplete="new-password" v-model="newPassword" />
      <input type="password" placeholder="Повторите новый" autocomplete="new-password" v-model="repeatNewPassword" />
      <Checkbox v-model="shouldLogOutEverywhere" label="Выйти из всех браузеров" id="shouldLogOutEverywhere" />
      <div class="button change" @click="changePassword">Сменить пароль</div>
    </div>
  </main>
</template>

<script>
import Checkbox from '@/components/Shared/Checkbox'
export default {
  name: 'ChangePassword',
  components: { Checkbox },
  props: ['resetToken'],
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      shouldLogOutEverywhere: false,
    }
  },

  mounted() {
    if(!this.$root.auth && !this.isResettingPassword) {
      this.$router.replace({ name: 'forbidden' })
    }

    if(this.isResettingPassword) {
      this.shouldLogOutEverywhere = true
    }
  },

  watch: {
    '$root.auth': function() { this.redirectHome() },
  },

  computed: {
    isResettingPassword() {
      return this.$route.name === 'reset-password-via-token'
    },
  },

  methods: {
    redirectHome() {
      if(!this.$root.auth) {
        this.$router.replace({ name: 'home' })
      }
    },

    changePassword() {
      if(this.newPassword !== this.repeatNewPassword) {
        alert('Новые пароли не совпадают. Попробуйте ещё раз')
        this.newPassword = ''
        this.repeatNewPassword = ''
        return
      }

      const requestData = {
        newPassword: this.newPassword,
        shouldLogOutEverywhere: this.shouldLogOutEverywhere
      }
      if(this.isResettingPassword) {
        requestData.resetToken = this.resetToken
      } else {
        requestData.oldPassword = this.currentPassword
      }

      this.$root.makeAPIRequest('auth', 'change_password', requestData)
        .then(response => {
          if(response.data.error) {
            console.error(response.data.message)
            alert(response.data.message)
            return
          }

          if(this.isResettingPassword) {
            this.redirectHome()
            return
          }

          const slug = this.$root.auth.username
          if(response.data.result.didLogOutEverywhere) {
            this.$root.dropSession()
          }
          this.$router.push({ name: 'user-profile', params: { slug } })
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
    display: flex;
  }

  .form {
    .small-form();
  }

  .big {
    .big-header();
  }

  input {
    .input();
    width: 100%;
  }

  .button {
    .big-button();
    align-self: center;
    background-color: @color-accent;
    margin-top: 0.5em;
  }
</style>
