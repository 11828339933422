<template>
  <div class="checkbox">
    <input :id="id" type="checkbox" :checked="value" @input="$emit('input', $event.target.checked)">
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['value', 'label', 'id'],
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .checkbox {
    margin: @xxs-margin 0;
    display: flex;
    align-items: center;
  }

  input {
    margin: 0;
    position: relative;

    appearance: none;
    width: 20px;
    height: 20px;

    &::after {
      content: '';
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid @color-accent;
    }

    &:checked::after {
      background-color: @color-accent;
    }
  }

  label {
    padding-left: @xxs-margin;
  }
</style>
