<template>
  <main>
    <div class="form">
      <span class="big">{{ hasName ? 'Смена имени' : 'Указать имя' }}</span>
      <span v-if="hasName">Ваше текущее имя: {{ $root.auth.username }}</span>
      <span>Вы можете менять имя не чаще, чем раз в сутки.</span>
      <input type="text" placeholder="Новое имя" v-model="newUsername" />
      <template v-if="!hasName">
        <span>
          Указывая имя, вы регистрируетесь на сайте, создаёте публичный профиль и соглашаетесь с
          <a href="/p/terms" target="_blank">пользовательским соглашением</a>
          и
          <a href="/p/privacy" target="_blank">политикой конфиденциальности</a>.
        </span>
        <span><br/>Соглашаюсь с условиями:</span>
        <Checkbox v-model="acceptedTerms" label="Пользовательского соглашения" id="acceptedTerms" />
        <Checkbox v-model="acceptedPrivacyPolicy" label="Политики конфиденциальности" id="acceptedPrivacyPolicy" />
      </template>
      <div class="button change" @click="changeUsername">{{ hasName ? 'Изменить' : 'Указать' }} имя</div>
    </div>
  </main>
</template>

<script>
import Checkbox from '@/components/Shared/Checkbox'
export default {
  name: 'ChangeUsername',
  components: { Checkbox },
  data() {
    return {
      newUsername: '',
      acceptedTerms: false,
      acceptedPrivacyPolicy: false,
    }
  },

  mounted() {
    if(!this.$root.auth) {
      this.$router.replace({ name: 'forbidden' })
    }
  },

  watch: {
    '$root.auth': function() { this.redirectHome() },
  },

  computed: {
    hasName() {
      return this.$root.auth && this.$root.auth.username !== null
    },
  },

  methods: {
    redirectHome() {
      if(!this.$root.auth) {
        this.$router.replace({ name: 'home' })
      }
    },

    changeUsername(event) {
      if(!this.hasName) {
        if(!this.acceptedPrivacyPolicy || !this.acceptedTerms) {
          alert('Вы должны принять условия пользовательского соглашения и политики конфиденциальности для того, чтобы создать профиль.')
          return
        }
      }

      this.$root.makeAPIRequest('user', 'change_username', {
        username: this.newUsername,
      })
        .then(response => {
          if(response.data.error) {
            console.error(response.data.message)
            alert(response.data.message)
            return
          }

          const newUsername = response.data.result.newUsername
          this.$cookies.set('helperia-next-username', newUsername)
          this.$root.auth.username = newUsername
          this.$router.push({ name: 'user-profile', params: { slug: newUsername } })
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
    display: flex;
  }

  .form {
    .small-form();
  }

  .big {
    font-family: @font-set-header;
    font-size: @font-size-header;
    display: block;
    text-align: center;
    margin-bottom: 0.5em;
  }

  input {
    .input();
    width: 100%;
  }

  .button {
    .big-button();
    align-self: center;
    background-color: @color-accent;
    margin-top: 0.5em;
  }

  span {
    margin-bottom: 0.5em;
  }
</style>
